










import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
@Component({
  components: { EmptyPlaceholder },
})
export default class GraphCard extends Vue {
  @Prop({})
  graphTitle!: string;

  @Prop({
    default: false,
  })
  hidePlaceholder!: boolean;

  get title() {
    return this.graphTitle;
  }
}
