



















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { DropdownItem } from '@/models/dropdown';
@Component({})
export default class DateDropdown extends Vue {
  selectedValue: string | null = null;
  isDropdownShown = false;

  @Prop({ required: true })
  selectedText!: string;

  @Prop({ default: false })
  isDisabled!: boolean;

  // This is for Edit purposes. Ideally for set the dropdownState
  @Prop()
  previousSelectedValue!: string;

  // Close Dropdown function
  closeDropdown(event: any) {
    if (!this.$el.contains(event.target)) {
      this.isDropdownShown = false;
    }
  }

  created() {
    document.addEventListener('click', this.closeDropdown);
  }

  beforeDestroy() {
    document.removeEventListener(`click`, this.closeDropdown);
  }

  @Emit(`select`)
  updateSelectedItem(selectedItem: DropdownItem) {
    this.isDropdownShown = false;
    this.selectedValue = selectedItem.value;
    return selectedItem;
  }

  @Emit(`updateState`)
  updateDropdownState(selectedItem: DropdownItem) {
    return selectedItem;
  }
}
