




























import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import GraphCard from '@/components/dashboard/GraphCard.vue';
import SmallPlacementCard from '@/components/dashboard/SmallPlacementCard.vue';
import BarChart from '@/components/common/BarChart.vue';
import DateDropdown from '@/components/common/DateDropdown.vue';
import { formatDateToMMMDDYYYY, getOneWeekFromDate } from '@/utils/moment';
import { DUAL_BAR_CONFIG, STACKED_BAR_CONFIG } from '@/utils/graphConfigurations';
import { ChartData } from 'chart.js';
@Component({
  components: {
    Page,
    GraphCard,
    SmallPlacementCard,
    BarChart,
    DateDropdown
  },
})
export default class Dashboard extends Vue {
  // UI State
  startDate = new Date(`January 01, 2022 00:00:00`);
  endDate = getOneWeekFromDate(this.startDate);
  userChartData: ChartData = {};
  averageTimeSpentChartData: ChartData = {};

  get dateDropdownTitle() {
    const formattedNewDate = formatDateToMMMDDYYYY(this.startDate);
    const formattedEndDate = formatDateToMMMDDYYYY(this.endDate);
    return `${formattedNewDate} - ${formattedEndDate}`;
  }
  
  get userGraphTitle() {
    return `Users`;
  }

  get averageTimeGraphTitle() {
    return `Average Time Spent on Platform`
  }

  get ongoingClassesCardTitle() {
    return `Ongoing Classes`
  }

  get totalClassConcludedTitle() {
    return `Total Classes Concluded `;
  }

  get userGraphConfig() {
    return STACKED_BAR_CONFIG;
  }

  get averageTimeSpentGraphConfig() {
    return DUAL_BAR_CONFIG;
  }

  get userData () {
    return this.userChartData;
  }

  get averageData() {
    return this.averageTimeSpentChartData;
  }

  //TODO: Sample number until API comes
  get totalCount () {
    return 0;
  }

  mounted() {
    // TODO: Replace this when we have API
    this.userChartData = {
      labels: [`Week 1`, `Week 2`, `Week 3`, `Week 4`, `Week 5`],
      datasets: [
        {
          barThickness: `flex`,
          maxBarThickness: 20,
          minBarLength: 2,
          label: `New Users`,
          backgroundColor: `#65CD7D`,
          data: [ 0, 0, 0, 0, 0 ],
        },
        {
          barThickness: `flex`,
          maxBarThickness: 20,
          minBarLength: 2,
          label: `Active Users`,
          backgroundColor: `#56D9FE`,
          data: [ 0, 0, 0, 0, 0 ],
        },
      ]
    };

    this.averageTimeSpentChartData = {
      labels: [`Week 1`, `Week 2`, `Week 3`, `Week 4`, `Week 5`],
      datasets: [
        {
          barThickness: `flex`,
          maxBarThickness: 20,
          minBarLength: 2,
          backgroundColor: `#F78133`,
          label: `Desktop App`,
          data: [ 0, 0, 0, 0, 0 ],
        },
        {
          barThickness: `flex`,
          maxBarThickness: 20,
          minBarLength: 2,
          label: `Mobile App`,
          backgroundColor: `#618A07`,
          data: [ 0, 0, 0, 0, 0 ],
        },
      ]
    };
  }
}
