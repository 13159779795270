
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator';
import { Bar, mixins } from 'vue-chartjs';
import { ChartOptions } from 'chart.js';

@Component({})
export default class BarChart extends Mixins(Vue, Bar, mixins.reactiveProp) {
  @Prop({
    type: Object,
    default: null,
  })
  options!: ChartOptions | null;

  mounted() {
    if (this.chartData && this.options) {
      this.renderChart(this.chartData, this.options);
    }
  }
}
