import { ChartOptions } from 'chart.js';

export const STACKED_BAR_CONFIG: ChartOptions = {
  maintainAspectRatio: false,
  legend: {
    position: `bottom`,
    labels: {
      usePointStyle: true,
      padding: 25,
      fontStyle: `bold`,
      fontSize: 16,
    },
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          beginAtZero: true,
          callback: function(tick: string | number) {
            return tick.toString();
          },
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        stacked: true,
      },
    ],
  },
};

export const DUAL_BAR_CONFIG: ChartOptions = {
  maintainAspectRatio: false,
  legend: {
    position: `bottom`,
    labels: {
      usePointStyle: true,
      padding: 25,
      fontStyle: `bold`,
      fontSize: 16,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          beginAtZero: true,
          callback: function(tick: string | number) {
            return tick.toString();
          },
        },
      },
    ],
    xAxes: [
      {
        offset: true,
      },
    ],
  },
};
