












import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class SmallPlacementCard extends Vue {
  @Prop({})
  cardTitle!: string;

  @Prop({})
  count!: number;

  get title() {
    return this.cardTitle;
  }

  get total() {
    return this.count;
  }
}
